<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        Actualizar precios
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-0">
        <v-card
          v-if="json.length == 0"
          flat
        >
          <v-card-text class="pt-0 pb-0">
            <v-row
              class="d-flex justify-center"
              style="border-style: dashed; border-width: 2px; border-radius: 15px; cursor: pointer;"
              :style="$vuetify.theme.dark ? 'background-color: #0f0f0f;' : 'background-color: #f0f0f0;'"
              no-gutters
              @dragover.prevent="hover = true"
              @dragleave.prevent="hover = false"
              @drop.prevent="drop"
              @click="$refs.input.click()"
            >
              <input
                ref="input"
                type="file"
                class="d-none"
                :accept="tipos.join(',')"
                @change="buscar($event)"
              >
              <v-col cols="12" class="d-flex justify-center mt-6">
                <v-icon
                  :large="hover ? false : true"
                  :x-large="hover ? true : false"
                >
                  {{ hover ? 'fas fa-folder-open' : 'fas fa-folder' }}
                </v-icon>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mt-2 mb-2">
                <h1 class="body-1 font-weight-bold mx-4 text-center">
                  Arrastre el archivo o haga click aquí
                </h1>
              </v-col>
              <v-col class="body-2 text-center mx-3">
                <p>
                  (si sube un archivo pesado puede demorar unos minutos en extraer la información)
                </p>
              </v-col>
            </v-row>
            <div
              class="d-flex body-2 pt-2"
              style="cursor: default"
            >
              <div>
                Tipo{{ tipos.length > 1 ? 's' : '' }} de archivo válido: {{ tipos.join(', ') }}
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-data-table
          v-else
          class="my-1 elevation-2 cebra"
          :items="json"
          :item-class="itemRowBackground"
          :headers="headers"
          :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
          :sort-by="['estado', 'articulo_codigo', 'lista_codigo']"
          multi-sort
          dense
        >
          <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
          <template
            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-icon
              :color="item.estado ? (item.estado == 1 ? '' : (item.estado == 2 ? 'warning' : 'success')) : 'orange'"
              :title="item.estado ? (item.estado == 1 ? 'Pendiente' : (item.estado == 2 ? item.error_desc : 'Se procesó con éxito')) : 'Línea duplicada'"
              small
            >
              {{ item.estado ? (item.estado == 1 ? 'far fa-clock' : (item.estado == 2 ? 'fas fa-exclamation-triangle' : 'fas fa-check')) : 'fas fa-exclamation-triangle' }}
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="error"
              title="Quitar línea"
              class="mr-3"
              :disabled="item.estado > 1"
              small
              @click="quitar(item); validar_datos()"
            >
              fas fa-times-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-row class="d-flex justify-end py-4 px-3">
          <v-btn
            :color="guardado ? 'secondary' : 'error'"
            class="mt-2 ml-2"
            :disabled="load"
            @click="dialog = false"
          >
            {{ guardado ? 'Volver' : 'Cancelar' }}
          </v-btn>
          <template v-if="json.length == 0">
            <download-excel
              name="Modelo de archivo para actualizar precios.xls"
              :data="[{a:'',l:'',p:'',v:''}]"
              :fields="{
                'Artículo código': 'a',
                'Lista código': 'l',
                'Precio': 'p',
                'Vigencia': 'v'
              }"
            >
              <v-btn
                color="green darken-3"
                class="mt-2 ml-2"
                dark
              >
                <v-icon left>fas fa-file-download</v-icon>
                Descargar modelo
              </v-btn>
            </download-excel>
            <v-btn
              color="primary"
              class="mt-2 ml-2"
              @click="$refs.input.click()"
            >
              <v-icon left>fas fa-folder-open</v-icon>
              Seleccionar archivo
            </v-btn>
          </template>
          <v-btn
            v-if="errores > 0"
            color="primary"
            class="mt-2 ml-2"
            @click="depurar()"
          >
            <v-icon left>fas fa-filter</v-icon>
            Depurar exitosos
          </v-btn>
          <BtnConfirmar
            clase="mt-2 ml-2"
            :loading="load"
            :disabled="bloquear || guardado"
            @action="actualizar_precios()"
          />
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Modal para subir un excel con precios y actualizarlos. Las columnas del excel deben ser:
 * 
 *  1ra col: artíoculo codigo
 *  2da col: lista codigo
 *  3ra col: precio nuevo
 *  4ta col: vigencia nueva
 * 
 * Lista de estados de los precios:
 * 
 *    0 : repetido
 *    1 : pendiente de subida
 *    2 : con error
 *    3 : ok
 */
import { read, utils } from 'xlsx'
import { tipos_archivos, format_money } from '../../../util/utils'
import BtnConfirmar from '../../util/BtnConfirmar'

export default {
  data () {
    return {
      errores: 0,
      load: false,
      hover: false,
      bloquear: true,
      guardado: false, // variable para cambiar el texto del botón de cancelar a volver cuando guarda
      json: [],
      headers: [
        { text: 'Artículo Código', value: 'articulo_codigo', align: 'end' },
        { text: 'Lista Código', value: 'lista_codigo', align: 'end' },
        { text: 'Precio', value: 'precio', align: 'end', formatter: format_money },
        { text: 'Vigencia', value: 'vigencia' },
        { text: 'Estado', value: 'estado', align: 'center' },
        { text: '', value: 'actions', sortable: false, align: 'end' }
      ],
      tipos: ['.xls', '.xlsx', '.ods'], // los tipos de archivos están en /util/utils.js
    }
  },
  props: {
    value: Boolean,
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      // limpia los valores cuando cierra el modal
      if (!val) {
        this.json = []
        this.errores = 0
        this.bloquear = true
        this.guardado = false
      }
    }
  },
  components: {
    BtnConfirmar
  },
  methods: {
    async actualizar_precios () {
      this.load = true
      this.errores = 0

      const data = await this.$store.dispatch('precios/format_data', this.json)

      await this.$store.dispatch('precios/insertar', { precios: data })
        .then(async (res) => {
          // actualzia el estado de los precios en la lista
          for (let index = 0; index < this.json.length; index++) {
            let precio = this.json[index]
            precio.estado = 3
          }
          // cambia la vigencia de los precios por la recien insertada
          await this.$store.dispatch('precios/actualizar')
            .then(() => {
              this.$swal.fire({
                icon: 'success',
                title: res.message,
                text: 'Se actualizaron ' + data.length + ' precios'
              })
            })
            .catch(error => {
              this.$swal.fire({
                icon: 'warning',
                title: res.message,
                text: 'Se actualizaron ' + data.length + ' precios pero no se pudo quitar de vigencia algunos precios anteriores: ' + error.message
              })
            })
        })
        .catch(error => {
          const correctos = error.stack
          if (correctos) {
            this.errores = 1
            // cambia el estado a los registros correctos
            for (let index = 0; index < correctos; index++) {
              let precio = this.json[index]
              precio.estado = 3
            }
            // cambia el estado a los erroneos
            for (let index = correctos; index < this.json.length; index++) {
              let precio = this.json[index]
              precio.error_desc = error.message
              precio.estado = 2
            }
            // muestra mensaje
            this.$swal.fire({
              icon: 'warning',
              title: 'Actualización de precios finalizada, revise el estado de los precios que no se pudieron cambiar',
              html: `Correctos: ${correctos} <br/> Con error: ${this.json.length - correctos}`
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
              timeout: 4000
            })
          }
        })
      
      /*
      // cicla por cada precio en la lista
      for (let index = 0; index < this.json.length; index++) {
        let precio = this.json[index]
        // inserta el precio nuevo
        await this.$store.dispatch('precios/insertar', precio)
          .then(() => {
            precio.estado = 3
          })
          .catch(error => {
            precio.estado = 2
            precio.error_desc = error.message
            this.errores += 1
          })
      }

      // arma un mensaje personalizado en base a los errores
      let icono = 'success'
      let mensaje = `Actualización de precios finalizada correctamente <p style="font-size: 22px; margin-top: 12px">Se cambiaron ${this.json.length} precios</p>`
      if (this.errores > 0) {
        icono = 'warning'
        mensaje = `Actualización de precios finalizada, revise el estado de los precios que no se pudieron cambiar
                  <p style="font-size: 22px; margin-top: 12px; margin-bottom: 0px">Correctos: ${this.json.length - this.errores} <br/> Con error: ${this.errores}</p>`
      }
      this.$swal.fire({
        icon: icono,
        title: mensaje
      })

      // cambia la vigencia de los precios por la recien insertada
      await this.$store.dispatch('precios/actualizar')
        .then(() => {
          // todo ok, muestra el mensaje personalizado
          this.$swal.fire({
            icon: icono,
            title: mensaje
          })
        })
        .catch(async (error) => {
          // muestra el mensaje personalizado primero y luego muestra un snakbar de error
          await this.$swal.fire({
            icon: icono,
            title: mensaje
          })
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
            timeout: 5000
          })
        })
      */

      this.guardado = true
      this.load = false

    },
    depurar () {
      for (let index = this.json.length - 1; index >= 0; index--) {
        const item = this.json[index]
        if (item.estado == 3) {
          this.quitar(item)
        }
      }
      this.validar_datos()
      this.guardado = false
      this.errores = 0
    },
    quitar (item) {
      const index = this.json.indexOf(item)
      this.json.splice(index, 1)
    },
    validar_datos () {
      // agrupa los precios por su pk
      let group_by_repetidos = this.json.reduce(function (r, a) {
        r[a.pk] = r[a.pk] || []
        r[a.pk].push(a)
        return r
      }, Object.create(null))

      // ciclo for por cada grupo de repetidos
      for (const key in group_by_repetidos) {
        let group = group_by_repetidos[key]
        // solo si el grupo tiene mas de uno significa que tiene repetidos
        if (group.length > 1) {
          // setea el estado de cada item repetido en 0
          for (const index in group) {
            let item = group[index]
            item.estado = 0
          }
        } else {
          group[0].estado = 1
        }
      }

      // solo si no existe ninguna row duplicada habilita el boton de guardar
      let duplicados = this.json.find(i => i.estado == 0)
      if (!duplicados) {
        this.bloquear = false
      }
      // si quito la ultima row deshabilita el boton de guardar
      if (this.json.length == 0) {
        this.bloquear = true
      }
    },
    to_json (file) {
      try {
        const reader = new FileReader()
        reader.onload = (e) => {
          const bytes = e.target.result
          const excel = read(bytes, { type: 'binary', cellDates: true })
          // obtiene el nombre de la primer hoja
          const hoja_nombre = excel.SheetNames[0]
          // obtiene la primer hoja
          const hoja = excel.Sheets[hoja_nombre]
          // obtiene la data de la primer hoja
          const data = utils.sheet_to_json(hoja, { header: 1 })
          /*
          // obtiene los headers de la primer hoja
          const headers = data[0]
          // formatea los headers para el datatable
          this.headers = []
          headers.forEach(header => {
            this.headers.push({
              text: header,
              value: header
            })
          })
          this.headers.push({ text: 'Quitar', value: 'actions', sortable: false, filterable: false, align: 'end'})
          // cicla por cada row de la primer hoja (excluyendo los headers)
          this.json = []
          for (let i = 1; i < data.length; i++) {
            const row = data[i]
            // solo si la row tiene algun dato lo agrega al json
            if (row.length > 0) {
              let item = {}
              // cicla por cada columna de la row
              for (let j = 0; j < row.length; j++) {
                const header = headers[j]
                let col = row[j]
                // si es fecha convierte el valor al formato DD/MM/YYYY
                try {
                  col = col.getUTCDate().toString().padStart(2, '0') + '/' + col.getMonth().toString().padStart(2, '0') + '/' + col.getFullYear()
                } catch (error) {
                  col = col
                }
                // agrega al objeto un atributo con el mismo nombre que el header de la columna
                item[header] = col
              }
              // añade el objeto al json
              this.json.push(item)
            }
          }
          */
          // cicla por cada row de la primer hoja (excluyendo los headers)
          this.json = []
          for (let i = 1; i < data.length; i++) {
            const row = data[i]
            // solo si la row tiene algun dato lo agrega al json
            if (row.length > 0) {
              let fecha = ''
              // si la fecha tiene formato de fecha la pasa a dd/mm/aaaa, sino la deja como está
              try {
                fecha = row[3].getUTCDate().toString().padStart(2, '0') + '/' + (row[3].getMonth() + 1).toString().padStart(2, '0') + '/' + row[3].getFullYear()
              } catch (error) {
                fecha = row[3]
              }
              this.json.push({
                pk: row[0].toString() + row[1].toString() + fecha.toString(),
                articulo_codigo: row[0],
                lista_codigo: row[1],
                precio: Math.round(row[2]),
                vigencia: fecha,
                estado: 1
              })
            }
          }
          this.validar_datos()
        }
        reader.readAsBinaryString(file)
      } catch (error) {
        this.json = []
        //this.headers = []
        this.$store.dispatch('show_snackbar', {
          text: 'Ocurrio un error inesperado al convertir el archivo a JSON: ' + error,
          color: 'error',
          timeout: 4000
        })
      }
    },
    validar (files) {
      // obtiene el archivo (en este caso solo puede subir 1)
      const file = files[0]
      const tipo = file.type

      // busca en el array de tipos_archivos el tipo de archivo del archivo que subio
      const tipo_archivo = tipos_archivos.find(a => a.mime == tipo)

      // verifica que exista el tipo de archivo en el array
      if (tipo_archivo) {
        // verifica que pertenezca por lo menos a un tipo de extension existente en el array de tipos
        if (this.tipos.find(a => a == tipo_archivo.extension)) {
          // el archivo que subio es correcto, pasa a convertirlo a json
          this.to_json(file)
        } else {
          // subio un archivo con una extension no valida
          this.$store.dispatch('show_snackbar', {
            text: 'Tipo de archivo no válido',
            color: 'error'
          })
        }
      } else {
        // subio un tipo de archivo que no esta registrado en el array tipos_archivos
        this.$store.dispatch('show_snackbar', {
          text: 'Tipo de archivo no válido para el sistema',
          color: 'error'
        })
      }
      
      // limpia el input de archivos
      this.$refs.input.value = ''
    },
    drop (event) {
      this.hover = false
      const files = event.dataTransfer.files
      if (files.length > 1) {
        this.$refs.input.value = ''
        this.$store.dispatch('show_snackbar', {
          text: 'Solo puede subir un archivo',
          color: 'error'
        })
        return
      }
      this.validar(files)
    },
    buscar (event) {
      const files = event.target.files
      if (files) this.validar(files)
    },
    itemRowBackground (item) {
      return !item.estado ? 'background-color: rowerr' : ''
    }
  }
}
</script>